<template>
  <div class="account-page">
    <PageHeader :items="items" />
    <div class="card">
      <div class="card-header">
        <h3>{{ $t("Profile") }}</h3>
      </div>
      <validation-observer ref="simpleRules">
        <div class="card-body">
          <b-row>
            <b-col lg="4" class="mb-2 mb-lg-0">
              <div class="profile-card">
                <div class="upload-img">
                  <img
                    v-if="user && user.profile_image"
                    :src="user.profile_image"
                    alt=""
                  />
                  <img
                    v-else
                    :src="require('@/assets/images/avatars/undefined.png')"
                    alt=""
                  />
                  <input
                    @change="handleFileChange"
                    type="file"
                    ref="fileInput"
                    style="display: none"
                  />
                  <div class="delete-acc" @click="$refs.fileInput.click()">
                    <ElementIcon v-bind:icon="'camimageIcon'" />
                  </div>
                </div>
              </div>
            </b-col>
            <b-col lg="8">
              <b-row class="align-items-end">
                <b-col lg="6" md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="First Name"
                    rules="required"
                  >
                    <div class="d-flex flex-column">
                      <TextInput
                        :label="$t('First Name')"
                        :type="'text'"
                        v-model="userUnit.firstName"
                        :placeholder="''"
                        :required="true"
                      />
                      <small class="text-danger" style="min-height: 20px">{{
                        errors[0]
                      }}</small>
                    </div>
                  </validation-provider>
                </b-col>

                <b-col lg="6" md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Last Name"
                    rules="required"
                  >
                    <div class="d-flex flex-column">
                      <TextInput
                        :label="$t('Last Name')"
                        :type="'text'"
                        v-model="userUnit.lastName"
                        :placeholder="''"
                        :required="true"
                      />
                      <small class="text-danger" style="min-height: 20px">{{
                        errors[0]
                      }}</small>
                    </div>
                  </validation-provider>
                </b-col>
                <b-col lg="6">
                  <div class="form-group">
                    <label class="form-label input-label" for="location">{{
                      $t("Location")
                    }}</label>
                    <multi-select
                      :multiple="false"
                      label="name"
                      track-by="name"
                      :options="countries"
                      v-model="userUnit.location"
                      :placeholder="$t('Select Option')"
                    />
                  </div>
                </b-col>
                <b-col lg="6">
                  <div class="form-group" v-if="userUnit.location">
                    <label
                      class="form-label input-label"
                      for="storage_location"
                      >{{ $t("Storage Location") }}</label
                    >
                    <div class="form-control">
                      <p v-if="userUnit.location">
                        {{ userUnit.location.name }}
                      </p>
                    </div>
                  </div>
                </b-col>
                <b-col lg="2">
                  <div class="form-group">
                    <label
                      class="form-label input-label"
                      for="address_for_invoices"
                      >{{ $t("Mobile phone number") }}</label
                    >
                    <multi-select
                      :multiple="false"
                      label="dial_code"
                      track-by="dial_code"
                      :options="countries"
                      v-model="userUnit.countryCode"
                      :placeholder="$t('Select Option')"
                    />
                  </div>
                </b-col>
                <b-col lg="5">
                  <div class="form-group">
                    <label
                      class="form-label input-label"
                      for="address_for_invoices"
                    >
                    </label>
                    <TextInput
                      :type="'number'"
                      v-model="userUnit.mobileNo"
                      :placeholder="''"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <TextInput
                  :label="$t('E-mail')"
                  :type="'text'"
                  v-model="form.mail"
                  :required="true"
                  :placeholder="''"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" class="my-2">
              <h3>{{ $t("Create a new password") }}</h3>
            </b-col>
            <b-col lg="6">
              <TextInput
                :label="$t('New Password')"
                :type="'password'"
                v-model="form.password"
              />
            </b-col>
            <b-col lg="6">
              <TextInput
                :label="$t('Re Enter new Password')"
                :type="'password'"
                v-model="form.confirmPassword"
              />
            </b-col>
          </b-row>
          <div class="d-flex align-items-center justify-content-end">
            <button
              @click="update"
              :class="{ 'cursor-not-allowed': isFormInvalid }"
              class="btn btn-secondary"
            >
              <span>{{ $t("Update Profile") }}</span>
            </button>
          </div>
          <!-- <div class="e-card mt-2">
            <div class="e-card-header pb-0">
              <h3>{{ $t("Address") }}</h3>
            </div>
            <div class="e-card-body">
              <b-row>
                <b-col lg="6">
                  <TextInput
                    :isReadonly="true"
                    :label="$t('Company')"
                    :type="'text'"
                    v-model="unit.company"
                    :placeholder="''"
                  />
                </b-col>
                <b-col lg="6">
                  <TextInput
                    :isReadonly="true"
                    :label="$t('Organizational Unit')"
                    :type="'text'"
                    v-model="unit.unit"
                    :placeholder="''"
                  />
                </b-col>
                <b-col lg="6">
                  <TextInput
                    :isReadonly="true"
                    :label="$t('Street')"
                    :type="'text'"
                    v-model="unit.street"
                    :placeholder="''"
                  />
                </b-col>
                <b-col lg="6">
                  <TextInput
                    :isReadonly="true"
                    :label="$t('Additional Information')"
                    :type="'text'"
                    v-model="unit.additionalInformation"
                    :placeholder="''"
                  />
                </b-col>
                <b-col lg="6">
                  <div class="row">
                    <div class="col-3">
                      <TextInput
                        :isReadonly="true"
                        :label="$t('Zip Code')"
                        :type="'text'"
                        v-model="unit.zipCode"
                        :placeholder="''"
                      />
                    </div>
                    <div class="col-9">
                      <TextInput
                        :isReadonly="true"
                        :label="$t('City')"
                        :type="'text'"
                        v-model="unit.city"
                        :placeholder="''"
                      />
                    </div>
                  </div>
                </b-col>
                <b-col lg="6">
                  <div class="form-group">
                    <label class="form-label input-label" for="country">{{
                      $t("Country")
                    }}</label>
                    <multi-select
                      disabled
                      :multiple="false"
                      label="name"
                      track-by="name"
                      :options="countries"
                      v-model="unit.country"
                      :placeholder="$t('Select Option')"
                    />
                  </div>
                </b-col>
                <b-col lg="6">
                  <div class="form-group">
                    <label
                      class="form-label input-label"
                      for="address_for_invoices"
                      >{{ $t("Address for Invoices") }}</label
                    >
                    <multi-select
                      disabled
                      :multiple="false"
                      label="name"
                      track-by="name"
                      :options="[]"
                      :placeholder="$t('Select Option')"
                    />
                  </div>
                </b-col>
                <b-col lg="6">
                  <TextInput
                    :isReadonly="true"
                    :label="$t('Contact Person')"
                    :type="'text'"
                    :placeholder="''"
                    v-model="unit.contactPerson"
                  />
                </b-col>
                <b-col lg="6">
                  <TextInput
                    :isReadonly="true"
                    :label="$t('E-Mail Contact Person')"
                    :type="'text'"
                    v-model="unit.contactPersonEmail"
                    :placeholder="''"
                  />
                </b-col>
              </b-row>
            </div>
          </div> -->
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { BLink, BRow, BCol } from "bootstrap-vue";
import Card from "@/components/elements/Card.vue";
import ElementIcon from "@/components/elements/Icon.vue";
import TextInput from "@/components/TextInput.vue";
import NotificationService from "../../../services/notification.service";
import { mapGetters } from "vuex";
import countries from "@/assets/data/countries.json";
import PageHeader from "@/components/PageHeader.vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import MultiSelect from "vue-multiselect";
import MultiSelectInput from "@/components/MultiSelectInput.vue";
export default {
  components: {
    Card,
    BLink,
    BRow,
    BCol,
    ElementIcon,
    ValidationProvider,
    ValidationObserver,
    TextInput,
    MultiSelectInput,
    MultiSelect,
    PageHeader,
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("roles", ["roles"]),
    ...mapGetters(["showLoader"]),

    colorSchemeOptions() {
      return [
        {
          label: this.$t("Dark Mode - Dental Twin style"),
          name: "dark-mode",
        },
        {
          label: this.$t("Light Mode - Dental Twin style"),
          name: "light-mode",
        },
      ];
    },
    items() {
      return [
        {
          text: this.$t("Dental Twin"),
          to: "/home",
        },
        {
          text: this.$t("My Info"),
          to: "/account",
        },
        {
          text: this.$t("Account"),
          active: true,
        },
      ];
    },
    isFormInvalid() {
      return (
        this.userUnit.firstName.length === 0 ||
        this.userUnit.lastName.length === 0
      );
    },
  },
  data() {
    return {
      selectedRows: [], // Array to hold selected row IDs
      countries,
      totalRecords: 0,
      pageLength: 10,
      isUnit: false,
      page: 1,
      userUnit: {
        firstName: "",
        lastName: "",
        role: null,
        mobileNo: "",
        unit: null,
        location: null,
        countryCode: "",
        colorScheme: null,
        isAgree: false,
        isNotifyMe: false,
      },
      form: {
        mail: "",
        password: "",
        confirmPassword: "",
      },
      unit: {
        company: "",
        unit: "",
        street: "",
        additionalInformation: "",
        zipCode: "",
        city: "",
        country: null,
        contactPerson: "",
        contactPersonEmail: "",
      },
      rows: [],
      searchTerm: "",
    };
  },
  async beforeCreate() {
    try {
      this.$store.commit("showLoader", true);
      this.$store
        .dispatch("auth/show", {
          id: localStorage.getItem("user_id"),
        })
        .then((res) => {
          this.form.mail = res?.data.email;
        });
      await this.$store.dispatch("roles/list", {
        limit_start: 0,
        limit_count: 100,
      });
      this.showUnitUserByUserId();
      this.showUnitByUserId();
    } catch (e) {
      console.error("api error ___", e);
    } finally {
      this.$store.commit("showLoader", false);
    }
  },
  methods: {
    async handleFileChange(event) {
      try {
        const file = event.target.files[0];
        await this.setProfilePicture(file, this.user);
        this.$store.dispatch("auth/show", { id: this.user.id }).then((res) => {
          this.$store.commit("auth/set_user", res?.data ?? {});
        });
      } catch (e) {
        console.log(e);
      }
    },
    // async detachUnit() {
    //   await this.$store
    //     .dispatch("units/detachUnit", {
    //       userId: this.user.id,
    //     })
    //     .then((res) => {
    //       this.resetForm();
    //       this.isUnit = false;
    //     });
    // },
    async storeUnitUser() {
      await this.$store.dispatch("unitUsers/create", {
        ...this.userUnit,
        roleId: this.userUnit?.role?.id ?? "",
        roleName: this.userUnit?.role?.title ?? "",
        userId: this.user.id,
        location: this.userUnit.location?.name ?? "",
        countryCode: this.userUnit?.countryCode?.dial_code ?? "",
        colorScheme: this.userUnit?.colorScheme?.name ?? "",
      });
    },
    resetForm() {
      this.unit = {
        company: "",
        unit: "",
        street: "",
        additionalInformation: "",
        zipCode: "",
        city: "",
        country: null,
        contactPerson: "",
        contactPersonEmail: "",
      };
    },
    // async storeUnit() {
    //   await this.$store
    //     .dispatch("units/create", {
    //       ...this.unit,
    //       country: this.unit.country?.name ?? "",
    //       userId: this.user.id,
    //     })
    //     .then((res) => {
    //       this.isUnit = true;
    //     });
    // },
    async showUnitUserByUserId() {
      this.$store
        .dispatch("unitUsers/showByUserId", {
          userId: localStorage.getItem("user_id"),
        })
        .then((res) => {
          const response = res?.data?.data;
          this.userUnit.firstName = response?.firstName;
          this.userUnit.lastName = response?.lastName;
          this.userUnit.mobileNo = response?.mobileNo;
          this.userUnit.isAgree = response?.isAgree;
          this.userUnit.isNotifyMe = response?.isNotifyMe;

          if (response?.roleId) {
            this.$store
              .dispatch("roles/show", {
                id: response?.roleId,
              })
              .then((res) => {
                this.userUnit.role = res?.data;
              });
          }
          if (response?.location) {
            this.userUnit.location = {
              name: response?.location,
            };
          }

          if (response?.countryCode) {
            this.userUnit.countryCode = {
              dial_code: response?.countryCode,
            };
          }

          if (response?.colorScheme) {
            this.userUnit.colorScheme = this.colorSchemeOptions.find(
              (option) => option.name === response.colorScheme
            );
          }
        });
    },
    async showUnitByUserId() {
      this.$store
        .dispatch("units/showByUserId", {
          userId: localStorage.getItem("user_id"),
        })
        .then((res) => {
          this.isUnit = true;
          const response = res?.data?.unit;
          this.unit.company = res?.data?.company;
          this.unit.unit = response?.unit;
          this.unit.street = response?.street;
          this.unit.additionalInformation = response?.additionalInformation;
          this.unit.zipCode = response?.zipCode;
          this.unit.city = response?.city;
          this.unit.contactPerson = response?.contactPerson;
          this.unit.contactPersonEmail = response?.contactPersonEmail;

          if (response?.country) {
            this.unit.country = {
              name: response?.country,
            };
          }
        });
    },
    async update() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          try {
            if (this.form.password.length || this.form.confirmPassword.length) {
              if (this.form.password !== this.form.confirmPassword) {
                NotificationService.showError(
                  "Password and confirm password do not match"
                );
                return;
              }
            }
            if (!this.form.password.length) {
              delete this.form["password"];
              delete this.form["confirmPassword"];
            }
            const payload = {
              ...this.form,
              id: this.user.id,
              first_name: this.userUnit.firstName,
              last_name: this.userUnit.lastName,
            };

            this.$store.commit("isLoading", true);
            await this.$store.dispatch("auth/update", payload);
            this.$store
              .dispatch("auth/show", {
                id: this.user.id,
              })
              .then((res) => {
                this.$store.commit("auth/set_user", res?.data ?? {});
                this.storeUnitUser();
              });
          } catch (e) {}
        }
      });
    },
    setProfilePicture(file, user) {
      return new Promise((resolve, reject) => {
        // convert to base64
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onerror = (err) => {
          console.log(err);
          reject(err);
        };
        reader.onloadend = async () => {
          let base64 = reader.result;
          // hit the auth update API to update the profile_image
          this.$store
            .dispatch("auth/update", {
              profile_image: base64,
              id: this.user.id,
            })
            .then(() => {
              resolve(base64);
            })
            .catch((err) => reject(err));
        };
      });
    },
  },
};
</script>

<style></style>
